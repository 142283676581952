@font-face {
  font-family: 'FjallaOne';
  src: local('FjallaOne'), url(./fonts/FjallaOne/FjallaOne-Regular.ttf) format('truetype');
}

body {
  margin: 0;
  font-family: 'FjallaOne', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
