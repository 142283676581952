.text-justify {
  text-align: justify;
}

.fullscreen-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.fullscreen-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}